<template>
  <div class="container-calendar">
    <div class="container-calendar-item">
      <HorizontalFilter :showTotalPerPage="false">
        <div class="fixed-filter-options div-filter" slot="content-filter-horizontal">
          <div class="text-right">
            <CheckboxSimple title="Mostrar realizadas" :markFormDirty="false" v-model="showDone"
              :changed="changedFilter" />
          </div>
        </div>
      </HorizontalFilter>
      <br>
      <FullCalendar :changedCalendar="changedCalendar" :clickedCalendar="clickedCalendar" :clickedEvent="clickedEvent"
        :loading="loading" />
    </div>
    <Modal title="Tarefa" :width="900" :height="750" v-show="showModal('job')">
      <JobCreateUpdate />
    </Modal>

  </div>
</template>

<script>

import FullCalendar from "@nixweb/nixloc-ui/src/component/shared/FullCalendar";
import HorizontalFilter from "@nixweb/nixloc-ui/src/component/shared/HorizontalFilter.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import JobCreateUpdate from '../../../components/modules/shared/job/JobCreateUpdate.vue'

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "DashboardView",
  components: {
    FullCalendar,
    HorizontalFilter,
    Toggle,
    CheckboxSimple,
    Modal,
    JobCreateUpdate
  },
  data() {
    return {
      urlGetJob: "/api/v1/shared/job/get-all-calendar",
      typeCalendar: "",
      dateStr: "",
      loading: false,
      showDone: false,
      titleModal: "",
      widthModal: 900,
      showPreview: false,
    };
  },
  mounted() {
    this.getJobAll();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    getJobAll() {
      this.loading = true;
      let params = {
        url: this.urlGetJob,
        obj: {
          userId: this.userLogged.user.id,
          done: this.showDone,
        },
      };
      this.getApi(params).then((response) => {
        this.addEvent({ name: "updateCalendar", data: response.content });
        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 500);
      });
    },
    changedFilter() {
      this.addEvent({ name: "cleanCalendar" });
      this.getJobAll();
    },
    clickedEvent(args) {
      this.addEvent({
        name: "jobUpdate",
        data: args.extendedProps.jobResponse,
      });
      this.openModal("job");
    },
    clickedCalendar(args) {
      const dataString = args.dateStr;
      const parts = dataString.split('-');
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];

      var startEnd = `${day}/${month}/${year}`;

      this.openModal("job");
      this.addEvent({ name: "jobCreateFromCalendar", data: startEnd })
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updatedJob") {
          this.addEvent({ name: "cleanCalendar" });
          this.getJobAll();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.container-calendar {
  margin: auto;
  padding-left: 110px;
  padding-right: 40px;
  max-width: 1300px;
}

.container-calendar-item {
  margin-top: 60px;
  margin-bottom: 40px;
}

.div-filter {
  margin-bottom: 30px;
  padding: 10px;
}
</style>
