<template>
  <div>
    <div v-if="loading">
      <Loading :center="false" />
    </div>
    <div v-else>
      <b-row>
        <b-col sm="6">
          <InputText title="Assunto" field="subject" :formName="formName" :required="true" :maxLength="100"
            v-model="job.subject" />
        </b-col>
        <b-col sm="6">
          <Select title="Destinatário" field="contact" :formName="formName" :markFormDirty="false"
            :showNewRegister="false" url="/api/v1/shared/contact/select-all-without-customer" v-model="job.contact">
          </Select>
        </b-col>
      </b-row>
      <CheckboxSimple title="Dia Inteiro" v-model="job.allDay" />
      <b-row>
        <b-col sm="3">
          <DateTime title="Data Inicio" field="birthDate" format="DD/MM/YYYY" type="date" :required="true" placeholder
            :formName="formName" v-model="job.dateStart" />
        </b-col>
        <b-col sm="2" v-if="!job.allDay">
          <DateTime title="Hora" field="birthDate" format="HH:mm" type="time" placeholder :formName="formName"
            v-model="job.timeStart" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Data Fim" field="birthDate" format="DD/MM/YYYY" type="date" :required="true" placeholder
            :formName="formName" v-model="job.dateEnd" />
        </b-col>
        <b-col sm="2" v-if="!job.allDay">
          <DateTime title="Hora" field="birthDate" format="HH:mm" type="time" placeholder :formName="formName"
            v-model="job.timeEnd" />
        </b-col>
      </b-row>
      <b-tabs>
        <b-tab title="Descrição">
          <span>Descrição</span>
          <ScrollBar :minHeight="150" :maxHeight="250">
            <EditorHtml field="description" :maxLength="5000" v-model="job.description" />
            <br>
          </ScrollBar>
        </b-tab>
        <b-tab title="Convidados">
          <CheckboxSimple title="Compartilhado com a Equipe" v-model="job.shared" />
          <div>
            <b-row v-if="!job.shared">
              <b-col sm="6">
                <Select title="Usuário Interno" :showNewRegister="false" field="user" url="/api/v1/adm/user/select-all"
                  v-model="selectUser" />
              </b-col>
              <b-col sm="4">
                <div class="btn-add">
                  <Button _key="btnAddUser" type="primary" :disabled="!selectUser.id" classIcon="fa-solid fa-plus"
                    size="small" :clicked="addUser" />
                </div>
              </b-col>
            </b-row>
            <InputTag title="Convidados" placeholder="Digite um e-mail e pressione Enter" :initialValue="job.guest"
              v-model="job.guest" />
          </div>
        </b-tab>
      </b-tabs>
      <div class="div-done">
        <CheckboxSimple title="Marcar como concluída" v-model="job.done" />
      </div>
      <b-row>
        <b-col sm="12">
          <div class="text-right">
            <Button _key="create" type="success" title="Salvar" :disabled="!isFormValid(formName) || loadingBtn"
              classIcon="fas fa-save" size="medium" :clicked="create" />
            <Button v-if="job.id && job.guest.length > 0" _key="updateNotify" type="info" title="Salvar e Notificar"
              :disabled="!isFormValid(formName) || loadingBtn" classIcon="fa-solid fa-floppy-disk-circle-arrow-right"
              size="medium" :clicked="updateNotify" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import InputAddressGoogle from "@nixweb/nixloc-ui/src/component/forms/InputAddressGoogle";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import Job from "@/components/modules/shared/job/Job.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "JobCreateUpdate",
  components: {
    Button, InputText, Toggle, DateTime, EditorHtml,
    ScrollBar, InputAddressGoogle, RadioGroup, CheckboxSimple, Loading, InputTag, Select
  },
  data() {
    return {
      formName: "jobCreateUpdate",
      job: new Job(),
      urlCreate: "/api/v1/shared/job/create",
      urlUpdate: "/api/v1/shared/job/update",
      selectUser: {},
      isGoogleMaps: true,
      loading: true,
      loadingBtn: false,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("validation", ["resetValidation"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "removeEvent",
      "hideModal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      this.loadingBtn = true;
      this.job.userId = this.userLogged.user.id;
      this.job.organizerName = this.userLogged.user.name;
      this.job.organizerEmail = this.userLogged.user.email;

      if (this.job.id) {
        let params = { url: this.urlUpdate, obj: this.job };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.addEvent({ name: "updatedJob" });
          this.removeLoading(["create", "updateNotify"]);
          this.loadingBtn = false;
        });
      } else {
        let params = { url: this.urlCreate, obj: this.job };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.addEvent({ name: "updatedJob" });
          this.removeLoading(["create"]);
          this.loadingBtn = false;
        });
      }
    },
    updateNotify() {
      this.job.notify = true;
      this.create();
    },
    update(data) {
      this.job = new Job();
      this.job.update(data);
      this.removeLoading([data.id]);
    },
    addUser() {
      if (!this.job.guest.includes(this.selectUser.content)) {
        this.job.guest.push(this.selectUser.content);
        this.selectUser = {};
      }
      this.removeLoading(["btnAddUser"]);
    },
    removerDuplicados(array) {
      return array.filter((element, index) => array.indexOf(element) === index);
    }

  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "jobUpdate") {
          this.update(event.data);
          this.loading = false;
        }

        if (event.name == "jobCreate" || event.name == "jobCreateFromCalendar" || event.name == "jobCreateFromChat") {
          this.job = new Job();
          this.resetValidation(this.formName);
          this.loading = false;
        }

        if (event.name == "jobCreateFromCalendar") {
          this.job.allDay = true;
          this.job.dateStart = event.data;
          this.job.dateEnd = event.data;
        }

        if (event.name == "jobCreateFromChat") {
          this.job.allDay = false;
          this.job.dateStart = event.data.dateStart;
          this.job.dateEnd = event.data.dateStart;
          this.job.guest.push(event.data.senderEmail);
          this.job.contact = event.data.contact;
          this.job.shared = true;
        }

      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-done {
  margin-top: 10px;
}

.btn-add {
  margin-top: 38px;
}

.div-notify {
  margin-top: 10px;
}
</style>